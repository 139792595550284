/** @format */

import * as React from 'react';

import { Grid2 } from '@mui/material';

import ContactUsForm from '../components/ContactUsForm.tsx';
import CardWithBGImage from '../components/CardWithBGImage.tsx';

export default function Contact() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        margin={'auto'}
        mt={12}
        size={{ xs: 12 }}>
        <ContactUsForm />
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        margin={'auto'}
        mt={15}
        size={{ xs: 12 }}>
        <CardWithBGImage />
      </Grid2>
    </>
  );
}
