/** @format */

import * as React from 'react';

import { Grid2 } from '@mui/material';

import KeyFeatures from '../components/KeyFeatures.tsx';
import CardWithBGImage from '../components/CardWithBGImage.tsx';
import KeeboHealthFeaturesCards from '../components/KeeboHealthFeaturesCards.tsx';

import '../css/cards.css';

export default function Features() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        container
        className='main-card-animations'
        margin={'auto'}
        mt={0}
        size={{ xs: 11, md: 10 }}>
        <KeeboHealthFeaturesCards />
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        // data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        container
        margin={'auto'}
        mt={15}
        size={{ xs: 12 }}>
        <KeyFeatures />
      </Grid2>

      {/*  */}

      <Grid2
        /* AOS */
        // data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        container
        margin={'auto'}
        mt={15}
        size={{ xs: 12 }}>
        <CardWithBGImage />
      </Grid2>
    </>
  );
}
