/** @format */

import React from 'react';
import { Box, Card, CardContent, Grid2, Typography } from '@mui/material';

import patientEnrollment from './../assets/images/patient-enrollment.avif';
import deviceIntegration from './../assets/images/device-integration.avif';
import dataMonitoring from './../assets/images/data-monitoring.avif';

import consultationsIcon from './../assets/svgs/consultations-svg.svg';
import realTimeUpdatesIcon from './../assets/svgs/real-time-updates-svg.svg';
import actionableInsightsIcon from './../assets/svgs/actionable-insights-svg.svg';
import '../css/cards.css';

const KeeboHealthFeaturesCards = () => {
  const featureCards = [
    {
      img: patientEnrollment,
      title: '1. Patient Enrollment',
      title2: 'Patients are enrolled into the KeeboHealth platform by their healthcare providers',
    },
    {
      img: deviceIntegration,
      title: '2. Device Integration',
      title2: 'Approved devices like blood pressure monitors, smartwatches, and weighing scales are integrated with the mobile app to capture vital data',
    },
    {
      img: dataMonitoring,
      title: '3. Data Monitoring',
      title2: 'The mobile app continuously monitors and records patient vitals, symptoms, and medication adherence',
    },
    {
      img: realTimeUpdatesIcon,
      title: '4. Real-Time Updates',
      title2: `Data is synced with the web portal, providing doctors with real-time updates on their patients' health status`,
    },
    {
      img: actionableInsightsIcon,
      title: '5. Actionable Insights',
      title2: 'Doctors receive actionable insights and personalized recommendations to optimize patient care',
    },
    {
      img: consultationsIcon,
      title: '6. Consultations',
      title2: 'Patients and doctors can communicate securely through the platform, ensuring timely interventions and support',
    },
  ];

  const _renderFeatureCard = (item, index) => {
    return (
      <Card
        /* AOS */
        // data-aos='fade-up'
        // data-aos-delay={index * 200} // Adds delay for stacking effect
        /* AOS */

        key={index}
        id={`card-${index + 1}}`}
        className={'card card-content'}
        sx={{
          display: 'flex',
          alignItems: 'center',

          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },

          border: '1px solid gray',
          borderRadius: '16px',

          margin: 'auto',
          marginTop: 10,
          backgroundColor: '#fff',

          width: { xs: 250, sm: 500, md: 700, lg: 700 },
          paddingTop: { xs: 2, sm: 2.5, md: 3, lg: 3 },
          paddingBottom: { xs: 2, sm: 2.5, md: 3, lg: 3 },
        }}>
        {/* Left Section - Illustration */}
        <Grid2 container size={4} alignItems='center' justifyContent='center'>
          <Box
            component='img'
            src={item.img}
            alt='Illustration'
            sx={{
              width: 250,
              height: 250,
            }}
          />
        </Grid2>

        {/* Right Section - Content */}
        <Grid2 size={7} sx={{ textAlign: 'left' }}>
          <CardContent>
            <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#1111827', marginBottom: 1 }}>{item.title}</Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#384855' }} color='text.secondary'>
              {item.title2}
            </Typography>
          </CardContent>
        </Grid2>
      </Card>
    );
  };

  return (
    <Grid2 id={'cards'} container direction={'column'} gap={9} mb={12} margin={'auto'} size={{ xs: 12 }}>
      {featureCards.map(_renderFeatureCard)}
    </Grid2>
  );
};

export default KeeboHealthFeaturesCards;
