/** @format */

import { Grid2, Typography } from '@mui/material';
import React from 'react';

export default function Card(props: any) {
  return (
    <Grid2>
      <div className='flip-card'>
        <div className='flip-card-inner' style={{ position: 'relative' }}>
          <div
            className='flip-card-front'
            style={{
              backgroundImage: `url(${props.cardItem.img})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',

              /* Applying black tint on the image */
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.45)',
              backgroundBlendMode: 'multiply',
            }}></div>
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              left: 20,
              color: 'white',
              textAlign: 'left',
            }}>
            <Typography sx={{ fontSize: '36px', fontWeight: 700, color: '#fff' }}>{props.cardItem.frontText}</Typography>
          </div>
          <div className='flip-card-back'>
            <Typography style={{ padding: '40px 10px 0px 30px', fontSize: '25px', fontWeight: 500, color: '#fff' }}> {props.cardItem.backText}</Typography>
          </div>
        </div>
      </div>
    </Grid2>
  );
}
