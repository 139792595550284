/** @format */

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type FAQAccordionType = {
  title: string;
  summary: string;
};

const FAQAccordion = (props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const FAQ = [
    {
      id: 1,
      title: 'How do I get started with KeeboHealth ?',
      summary: 'Patients can get started by enrolling through their healthcare provider. Once enrolled, youll receive the necessary devices and access to the mobile app.',
    },
    {
      id: 2,
      title: 'What devices are compatible with KeeboHealth?',
      summary: 'KeeboHealth integrates with approved blood pressure monitors, smartwatches, and weighing scales. Check our device compatibility list for more details.',
    },
    {
      id: 3,
      title: 'How secure is my data with KeeboHealth?',
      summary: 'We prioritize your privacy and security. All data is encrypted and stored securely. Only authorized healthcare providers can access your information.',
    },
    {
      id: 4,
      title: 'Can I use KeeboHealth on multiple devices?',
      summary: 'Yes, you can use the KeeboHealth mobile app on multiple devices as long as they are registered under your account.',
    },
    {
      id: 5,
      title: 'What should I do if I experience technical issues?',
      summary: 'If you encounter any technical issues, please contact our Product Support team for assistance.',
    },
  ];

  const _renderFAQItems = (item: FAQAccordionType, index: number) => {
    return (
      <Accordion key={index} sx={{ padding: 1.5 }} expanded={expanded === item.title} onChange={handleChange(item.title)}>
        <AccordionSummary
          expandIcon={
            expanded === item.title ? <RemoveIcon /> : <AddIcon /> // Toggle icon based on expanded state
          }
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}>
          <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#111827' }}>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#384855' }}>{item.summary}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  return <>{FAQ.map((item: FAQAccordionType, index: number) => _renderFAQItems(item, index))}</>;
};

export default FAQAccordion;
