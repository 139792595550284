/** @format */

import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import KeeboHealth from '../src/pages/KeeboHealth.tsx';
import Plans from '../src/pages/Plans.tsx';
import Features from './pages/Features.tsx';
import Contact from './pages/Contact.tsx';

export const NavRoutes: RouteObject[] = [
  {
    path: '/',
    element: <KeeboHealth />,
  },
  {
    path: '/plans',
    element: <Plans />,
  },
  {
    path: '/features',
    element: <Features />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
];

export default NavRoutes;
