/** @format */

import React from 'react';
import { Grid2, Typography } from '@mui/material';

import summaryIcon from '../assets/svgs/summary-icon.svg';
import prescriptionIcon from '../assets/svgs/prescription-icon.svg';
import appointmentsIcon from '../assets/svgs/appointments-icon.svg';
import vitalsHistoryIcon from '../assets/svgs/vitals-history-icon.svg';

import timelineIcon from '../assets/images/timeline.png';
import gdmtMedicationIcon from '../assets/images/medicines.png';

const KeyFeatures = () => {
  const keyFeatures = [
    {
      icon: prescriptionIcon,
      title: 'Online Prescriptions',
      title2: 'In the new era of technology we look in the future with certainty and pride.',
    },
    {
      icon: appointmentsIcon,
      title: 'Virtual and Physical Appointments',
      title2: 'Easily schedule and manage both virtual and physical appointments directly from the platform.',
    },
    {
      icon: vitalsHistoryIcon,
      title: 'Vitals History as RCA Chart',
      title2: 'In the new era of technology we look in the future with certainty and pride.',
    },
    {
      icon: summaryIcon,
      title: 'Summary of Patient Vitals',
      title2: 'Access a comprehensive view of patient vitals, including real-time updates and historical data.',
    },
    {
      icon: gdmtMedicationIcon,
      title: 'GDMT Medication Class and Dosage',
      title2: 'Enhanced support for GDMT (Guideline-Directed Medical Therapy) with detailed medication classes and dosage information.',
    },
    {
      icon: timelineIcon,
      title: 'Timelines of Medication & Investigation',
      title2: `Visualize the patient's medication history and investigation results over time.`,
    },
  ];

  const _renderKeyFeatures = (item, index) => {
    return (
      <Grid2 key={index} container columnGap={3} alignItems={'center'}>
        <Grid2 sx={{ border: '1px solid red', padding: 2.5, borderRadius: 20, borderColor: 'rgb(205, 209, 212)' }}>
          <img src={item.icon} alt='notes' width={50} />
        </Grid2>
        <Grid2 container direction={'column'} gap={2}>
          <Typography sx={{ fontSize: '22px', fontWeight: 600, color: '#1111827' }}>{item.title}</Typography>
          <Typography maxWidth={500} sx={{ fontSize: '18px', fontWeight: 400, color: '#384855' }}>
            {item.title2}
          </Typography>
        </Grid2>
      </Grid2>
    );
  };

  return (
    <Grid2 container margin={'auto'} direction={'column'} gap={8} mb={12}>
      <Grid2 container margin={'auto'} size={{ xs: 12 }}>
        <Typography sx={{ fontSize: '56px', fontWeight: 600, color: '#101827' }}>Key features</Typography>
      </Grid2>
      {/*  */}
      <Grid2 container gap={6} margin={'auto'} size={{ xs: 12 }}>
        {keyFeatures.map((item, index) => _renderKeyFeatures(item, index))}
      </Grid2>
    </Grid2>
  );
};

export default KeyFeatures;
