/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Grid2, MenuItem, Typography, useMediaQuery } from '@mui/material';

import YouTube from '@mui/icons-material/YouTube';
import X from '@mui/icons-material/X';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  return (
    <>
      <Grid2
        /* AOS */
        // data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */
        container
        sx={{
          // width: '80vw',
          margin: 'auto',
          marginTop: 15,
          paddingBottom: 5,
        }}
        // size={12}
        rowGap={6}
        columnGap={25}
        direction={(!isMobile && 'row') || 'column'}
        size={{ xs: 12 }}>
        {/*  */}
        <Grid2 container direction='column' gap={7}>
          <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#515f6a' }} maxWidth={360}>
            Tricog is a leader in the Medtech space solving critical Cardiovascular conditions by identifying them in time and accurately.
          </Typography>
          {/*  */}
          <Grid2 container direction={'column'} gap={2}>
            <Grid2>
              <Typography sx={{ fontSize: '22px', fontWeight: 600, color: '#515f6a' }}>Follow us:</Typography>
            </Grid2>
            <Grid2 container direction={'row'} gap={1.5}>
              <MenuItem
                onClick={() => window.open('https://www.facebook.com/tricog/')}
                sx={{ padding: 1, border: '1px solid rgb(155, 163, 170)', borderRadius: 50, '&:hover': { backgroundColor: 'rgb(59, 130, 246)', color: 'white' } }}>
                <FacebookIcon fontSize='medium' />
              </MenuItem>

              <MenuItem
                onClick={() => window.open('https://x.com/tricoghealth/')}
                sx={{ padding: 1, border: '1px solid rgb(155, 163, 170)', borderRadius: 50, '&:hover': { backgroundColor: 'rgb(59, 130, 246)', color: 'white' } }}>
                <X fontSize='medium' />
              </MenuItem>

              <MenuItem
                onClick={() => window.open('https://www.instagram.com/tricoghealth/')}
                sx={{ padding: 1, border: '1px solid rgb(155, 163, 170)', borderRadius: 50, '&:hover': { backgroundColor: 'rgb(59, 130, 246)', color: 'white' } }}>
                <InstagramIcon fontSize='medium' />
              </MenuItem>

              <MenuItem
                onClick={() => window.open('https://in.linkedin.com/company/tricog-health')}
                sx={{ padding: 1, border: '1px solid rgb(155, 163, 170)', borderRadius: 50, '&:hover': { backgroundColor: 'rgb(59, 130, 246)', color: 'white' } }}>
                <LinkedInIcon fontSize='medium' />
              </MenuItem>

              <MenuItem
                onClick={() => window.open('https://www.youtube.com/channel/UCV-uGseptgruiCZrFuPz9SA')}
                sx={{ padding: 1, border: '1px solid rgb(155, 163, 170)', borderRadius: 50, '&:hover': { backgroundColor: 'rgb(59, 130, 246)', color: 'white' } }}>
                <YouTube fontSize='medium' />
              </MenuItem>
            </Grid2>
          </Grid2>
        </Grid2>

        {/*  */}

        <Grid2 container gap={3} direction={'column'}>
          <Grid2>
            <Typography sx={{ fontSize: '22px', fontWeight: 700, color: '#111827' }}>Resources</Typography>
          </Grid2>
          {/*  */}
          <Grid2 container direction={'column'} gap={1}>
            <MenuItem sx={{ padding: 0 }} onClick={() => window.open('https://www.tricog.com/about-us/')}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#384855' }}>About Tricog</Typography>
            </MenuItem>

            <MenuItem sx={{ padding: 0 }} onClick={() => navigate('/features')}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#384855' }}>Features</Typography>
            </MenuItem>

            <MenuItem sx={{ padding: 0 }} onClick={() => navigate('/plans')}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#384855' }}>Payment Plans</Typography>
            </MenuItem>

            <MenuItem sx={{ padding: 0 }} onClick={() => navigate('/contact')}>
              <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#384855' }}>Contact</Typography>
            </MenuItem>
          </Grid2>
        </Grid2>
      </Grid2>
      {/*  */}
      <Grid2 container m={'auto'} mt={2.5} mb={2.5} size={{ xs: 12 }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#515f6a' }}>© Copyright 2024, All Rights Reserved by Tricog Health</Typography>
      </Grid2>
    </>
  );
};

export default Footer2;
