/** @format */

import React from 'react';
import { Button, CircularProgress, Divider, Grid2, MenuItem, TextField, Typography } from '@mui/material';

import config from '../config/config.ts';

const ContactUsForm = () => {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  const [isFormUpdating, setIsFormUpdating] = React.useState(false);

  const [form, setForm] = React.useState<any>({});

  const handleChange = (value: string, variable: string) => {
    setForm((prev) => ({ ...prev, [variable]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsFormUpdating(true);

    const data = {
      name: form.name,
      email: form.email,
      role: form.role,
      lookingFor: form.lookingFor,
      description: form.description,
    };

    try {
      await fetch(config.googleAppScriptEmailURL, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'text/plain;charset=utf-8',
        },
      });

      setIsFormSubmitted(true);
    } catch (err) {
      console.log('err', err);
      alert('Error while submitting the Form. Please try again later');
    }

    setIsFormUpdating(false);
  };

  return (
    <Grid2 container direction={'row'} spacing={2} gap={10} flexWrap={'wrap'} justifyContent={'center'} margin={'auto'} size={{ xs: 11, md: 10 }}>
      {/* Left */}
      <Grid2 container direction={'column'} gap={5} size={{ xs: 12, md: 5 }}>
        <Grid2 container>
          <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#111827' }}>
            If you are interested in our platform, our team of experts is ready to answer your questions, give you more info and help you.
          </Typography>
        </Grid2>
        <form onSubmit={handleSubmit}>
          <Grid2 container gap={3}>
            <TextField
              fullWidth
              name='name'
              type='text'
              label={'Name'}
              variant='outlined'
              sx={{
                '.MuiOutlinedInput-root': {
                  color: 'gray',
                  borderRadius: 4,
                  backgroundColor: '#F5F5F5',
                },
                '.MuiFormLabel-root': {
                  color: 'gray',
                },
              }}
              value={form.name}
              onChange={(event) => handleChange(event.target.value, 'name')}
              required={true}
            />
            <TextField
              fullWidth
              name='email'
              type='email'
              label={'Email'}
              variant='outlined'
              sx={{
                '.MuiOutlinedInput-root': {
                  color: 'gray',
                  borderRadius: 4,
                  backgroundColor: '#F5F5F5',
                },
                '.MuiFormLabel-root': {
                  color: 'gray',
                },
              }}
              value={form.email}
              onChange={(event) => handleChange(event.target.value, 'email')}
              required={true}
            />
            {/* <TextField
              select
              fullWidth
              type='text'
              variant='outlined'
              name='doctor_or_patient'
              label={'Are you a doctor or a patient ?'}
              sx={{
                '.MuiOutlinedInput-root': {
                  color: 'gray',
                  borderRadius: 4,
                  backgroundColor: '#F5F5F5',
                },
                '.MuiFormLabel-root': {
                  color: 'gray',
                },
              }}
              value={form.role}
              onChange={(event) => handleChange(event.target.value, 'role')}
              required={true}>
              <MenuItem value={'DOCTOR'}>I am a Doctor</MenuItem>
              <MenuItem value={'PATIENT'}>I am someone who needs cardiac care</MenuItem>
              <MenuItem value={'OTHER'}>I am none of the above</MenuItem>
            </TextField>
            <TextField
              select
              fullWidth
              type='text'
              variant='outlined'
              name='looking_for'
              label={'What are you looking for ?'}
              sx={{
                '.MuiOutlinedInput-root': {
                  color: 'gray',
                  borderRadius: 4,
                  backgroundColor: '#F5F5F5',
                },
                '.MuiFormLabel-root': {
                  color: 'gray',
                },
              }}
              onChange={(event) => handleChange(event.target.value, 'lookingFor')}
              value={form.lookingFor}
              required={true}>
              <MenuItem value={'DEMO'}>Demo request</MenuItem>
              <MenuItem value={'FEEDBACK'}>Product feedback</MenuItem>
              <MenuItem value={'OTHER'}>Something else</MenuItem>
            </TextField> */}

            <div className='form-group' style={{ width: '100%' }}>
              {/* <label htmlFor='doctor_or_patient' style={{ color: 'gray' }}>
                Are you a doctor or a patient?
              </label> */}
              <select
                id='doctor_or_patient'
                name='doctor_or_patient'
                value={form.role}
                onChange={(event) => handleChange(event.target.value, 'role')}
                required
                style={{
                  width: '100%',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  borderRadius: '20px',
                  border: '1px solid #ccc',
                  color: 'gray',

                  fontFamily: 'Inter',
                  fontSize: '16px',

                  /* Down arrow css */
                  appearance: 'none',
                  background: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 4 5%27%3E%3Cpath fill=%27%23ccc%27 d=%27M2 2L0 0h4z%27/%3E%3C/svg%3E') no-repeat right 20px center`,
                  backgroundSize: '10px',
                  backgroundPositionY: 'center',

                  backgroundColor: '#F5F5F5',
                }}>
                <option value=''>Are you a doctor or a patient ?</option>
                <option value='DOCTOR'>I am a Doctor</option>
                <option value='PATIENT'>I am someone who needs cardiac care</option>
                <option value='OTHER'>I am none of the above</option>
              </select>
            </div>

            <div className='form-group' style={{ width: '100%' }}>
              {/* <label htmlFor='looking_for' style={{ color: 'gray' }}>
                What are you looking for?
              </label> */}
              <select
                id='looking_for'
                name='looking_for'
                value={form.lookingFor}
                onChange={(event) => handleChange(event.target.value, 'lookingFor')}
                required
                style={{
                  width: '100%',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  borderRadius: '20px',
                  border: '1px solid #ccc',
                  color: 'gray',

                  fontFamily: 'Inter',
                  fontSize: '16px',

                  /* Down arrow css */
                  appearance: 'none',
                  background: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 4 5%27%3E%3Cpath fill=%27%23ccc%27 d=%27M2 2L0 0h4z%27/%3E%3C/svg%3E') no-repeat right 20px center`,
                  backgroundSize: '10px',
                  backgroundPositionY: 'center',

                  backgroundColor: '#F5F5F5',
                }}>
                <option value=''>What are you looking for ?</option>
                <option value='DEMO'>Demo request</option>
                <option value='FEEDBACK'>Product feedback</option>
                <option value='OTHER'>Something else</option>
              </select>
            </div>

            <TextField
              rows={5}
              fullWidth
              multiline
              type='text'
              name='assist'
              variant='outlined'
              label={'How can we assist you ?'}
              sx={{
                '.MuiOutlinedInput-root': {
                  color: 'gray',
                  borderRadius: 4,
                  backgroundColor: '#F5F5F5',
                },
                '.MuiFormLabel-root': {
                  color: 'gray',
                },
              }}
              onChange={(event) => handleChange(event.target.value, 'description')}
              value={form.description}
              required={true}
            />

            {/*  */}

            <Button type='submit' fullWidth variant='contained' sx={{ padding: 2, backgroundColor: 'rgb(51, 51, 51) !important', borderRadius: 3 }} disabled={isFormUpdating || isFormSubmitted}>
              {isFormUpdating && <CircularProgress />}
              {!isFormUpdating && (
                <Typography textTransform={'none'} sx={{ fontSize: '14px', fontWeight: 600, color: '#fff !important' }}>
                  {!isFormSubmitted ? 'Submit' : 'Thank you. We will get back to you soon.'}
                </Typography>
              )}
            </Button>
          </Grid2>
        </form>
      </Grid2>

      {/* Divider */}
      <Divider orientation='vertical' variant='fullWidth' flexItem />

      {/* Right */}
      <Grid2 container direction={'column'} size={{ xs: 12, md: 5 }}>
        <Grid2 container direction={'column'} width={'fit-content'}>
          <Typography sx={{ fontSize: '22px', fontWeight: 600, color: '#111827' }}>Phone</Typography>
          <Divider />
          <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#384855' }}>+91 8050006866</Typography>
        </Grid2>
        <Grid2 container direction={'column'} width={'fit-content'}>
          <Typography sx={{ fontSize: '22px', fontWeight: 600, color: '#111827' }}>Mail</Typography>
          <Divider />
          <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#384855' }}>keebohealth@tricog.com</Typography>
        </Grid2>
        <Grid2 container direction={'column'} width={'fit-content'}>
          <Typography sx={{ fontSize: '22px', fontWeight: 600, color: '#111827' }}>Address</Typography>
          <Divider />
          <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#384855' }}>
            Tricog Health (India) PVT. LTD, <br />
            A unit of Tricog Health PTE. LTD,
            <br />
            Old #82, New #3 PID No 5-24-3,
            <br />
            2nd Main Road Vyalikaval <br />
            Extension, Malleswaram Bengaluru, <br />
            Karnataka 560003 IN
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ContactUsForm;
