/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid2, Typography, Button, Divider, useMediaQuery, MenuItem } from '@mui/material';

import keeboHealthLogo from '../assets/images/keeboHealthLogo.avif';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Grid2
      /* AOS */
      // data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */
      gap={5}
      pt={11}
      container
      direction={'column'}
      sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#111827' }}>
      {/* TITLE */}
      <Grid2 textAlign={(isMobile && 'center') || 'left'}>
        <Typography sx={{ fontSize: '54px', fontWeight: 700, color: '#fff' }}>Get in touch with us.</Typography>
      </Grid2>

      {/* BUTTON */}
      <Grid2 container direction={'row'}>
        <Button
          variant='contained'
          size='large'
          sx={{
            borderRadius: 50,
            '&:hover': {
              backgroundColor: 'black',
              color: '#fff',
              border: 'none',
            },
            backgroundColor: '#007aff',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
          onClick={() => navigate('contact')}>
          <Typography textTransform={'none'} sx={{ fontSize: '18px', fontWeight: 600, color: '#fff' }}>
            Contact Us
          </Typography>
        </Button>
      </Grid2>

      <Grid2 container mt={0}></Grid2>

      {/* DIVIDER */}
      <Divider sx={{ bgcolor: 'gray' }} orientation='horizontal' variant='fullWidth' flexItem />

      {/* LOGO & RESOURCES */}
      <Grid2 container direction={(!isMobile && 'row') || 'column'} /* gap={25} */ columnGap={25} rowGap={10} p={5}>
        {/* LOGO & COMPANY NAME */}
        <Grid2 container direction={'row'} gap={2} alignItems={'flex-start'} height={10}>
          {/* LOGO */}
          <Grid2>
            <img src={keeboHealthLogo} alt='keebo-health-logo' style={{ width: isMobile ? '150px' : '185px', height: 'auto' }} />
          </Grid2>

          {/* DIVIDER */}
          <Grid2 alignSelf={'stretch'}>
            <Divider sx={{ bgcolor: 'gray' }} orientation='vertical' variant='fullWidth' />
          </Grid2>

          {/* COMPANY NAME */}
          <Grid2 alignSelf={'flex-end'}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#fafafb' }}>by Tricog</Typography>
          </Grid2>
        </Grid2>

        {/* DIVIDER */}
        {!isMobile && (
          <Grid2>
            <Divider sx={{ bgcolor: 'gray' }} orientation='vertical' variant='fullWidth' />
          </Grid2>
        )}

        {/* RESOURCES */}
        <Grid2 container direction={'column'} gap={2} alignItems={'flex-start'}>
          <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#fff' }}>Resources</Typography>

          <MenuItem onClick={() => window.open('https://www.tricog.com/about-us/')} sx={{ padding: 0 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#fff' }}>About Tricog</Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/features')} sx={{ padding: 0 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#fff' }}>Features</Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/plans')} sx={{ padding: 0 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#fff' }}>Payment Plans</Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/contact')} sx={{ padding: 0 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400, color: '#fff' }}>Contact</Typography>
          </MenuItem>
        </Grid2>
      </Grid2>

      {/* DIVIDER */}
      <Divider sx={{ bgcolor: 'gray' }} orientation='horizontal' variant='fullWidth' flexItem />

      {/* COPYRIGHT */}
      <Grid2 container direction={'column'} justifyContent={'flex-start'} pb={5}>
        <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#eff1f3' }}>© Copyright 2024, All Rights Reserved by Tricog Health</Typography>
      </Grid2>
    </Grid2>
  );
};

export default Footer;
