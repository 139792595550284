/** @format */

import AOS from 'aos';
import React from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid2, Typography, useMediaQuery } from '@mui/material';

import { NavRoutes } from './navigation_routes.tsx';

import Footer from './components/Footer.tsx';
import Footer2 from './components/Footer2.tsx';
import Navbar from './components/navigation-bar/Navbar.tsx';

import header from './../src/assets/images/header.jpg';

import './App.scss';
import 'aos/dist/aos.css';

interface AppProps {}

const HomeApp: React.FunctionComponent<AppProps> = () => {
  return useRoutes(NavRoutes);
};

function App() {
  const location = useLocation();
  const { hash, pathname, search } = location;

  const theme = createTheme({
    typography: {
      fontFamily: 'Inter',
    },
  });

  const isMobileSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [landingPageChildMarginTop, setLandingPageChildMarginTop] = React.useState(0);

  const smoothScrollRef = React.createRef<HTMLDivElement | any>();
  const bgImageRef = React.useRef<HTMLDivElement>(null);

  const updateLandingPageChildMargin = () => {
    if (bgImageRef.current) {
      const bgImgHeight = bgImageRef && bgImageRef?.current && bgImageRef?.current?.clientHeight;
      setLandingPageChildMarginTop((prev) => (prev !== bgImgHeight ? bgImgHeight : prev));
    }
  };

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLandingPageChildMargin();
    });

    if (bgImageRef.current) {
      resizeObserver.observe(bgImageRef.current);
    }
  }, []);

  function hasNoSpecifiedTexts(input) {
    const keywords = ['plans', 'features', 'contact'];
    return keywords.some((keyword) => input.includes(keyword));
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 160, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
    AOS.refresh(); // Refresh animations on content change
  }, []);

  const landingPageChildCard = (
    <Grid2
      /* AOS */
      data-aos='fade-up'
      data-aos-offset='-500'
      // data-aos-duration='1000'
      /* AOS */

      container
      gap={3}
      direction='column'
      maxWidth={'1440px'}
      justifyContent='space-between'
      sx={{
        textAlign: 'left',
        marginTop: {
          // xxs: `-${landingPageChildMarginTop - 390}px` || '10%',
          xs: `${landingPageChildMarginTop / 300}px` || '10%',
          sm: `${landingPageChildMarginTop / 10}px` || '10%',
          md: `${landingPageChildMarginTop / 5}px` || '10%',
          lg: `${landingPageChildMarginTop / 5}px` || '10%',
        },
        '@media (max-width: 375px)': {
          marginTop: `-${landingPageChildMarginTop - 585}px` || '10%',
        },
      }}
      size={{ xs: 12 }}>
      {/* Text 1 */}
      <Grid2 container size={12} justifyContent={'space-between'}>
        <Grid2 size={7}>
          <Typography sx={{ fontSize: '18px', fontWeight: 500, color: 'white' }}>Your Go To Telemedicine Platform</Typography>
        </Grid2>
        <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 1.5, xl: 1.3 }}></Grid2>
      </Grid2>
      {/* Text 2 & Svg */}
      <Grid2 container size={12} justifyContent={'space-between'}>
        {/* Text 2 */}
        <Grid2 container size={{ xs: 12, md: 7 }} gap={3}>
          <Grid2>
            <Typography
              sx={{
                fontSize: '54px',
                fontWeight: 600,
                color: 'white',
              }}>
              Connected Cardiac Care
            </Typography>
            <Typography sx={{ fontSize: '35px', fontWeight: 600, color: '#FF8800' }}>Empowering Doctors, Enhancing Patient Lives</Typography>
          </Grid2>
          <Grid2>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                color: 'white',
              }}
              color='white'>
              Revolutionizing heart failure management with innovative remote monitoring and personalized care solutions.
            </Typography>
          </Grid2>
        </Grid2>
        {/* Svg */}
        {!isMobileSizeSm && (
          <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 1.5, xl: 1.3 }}>
            <svg height='auto' width='100%' viewBox='0 0 190 190' preserveAspectRatio='xMidYMid meet'>
              {/* Background Circles */}
              <circle r='90' cx='100' cy='100' fill='#3B82F6' />
              <circle r='55' cx='100' cy='100' stroke='white' strokeWidth='1' fill='transparent' />

              {/* Rotating Text */}
              <g>
                <path
                  id='curve'
                  d='M 135,100
                      m -100, 0
                      a 55,55 0 1,1 132,0
                      a 55,55 0 1,1 -132,0'
                  fill='transparent'
                  stroke='none'
                />
                <text className='rotating-text' fill='white' fontSize='22' fontWeight='bold'>
                  <textPath href='#curve' startOffset='0%' textAnchor='start' style={{ fontFamily: 'Inter', fontSize: '23px', fontWeight: 600, color: 'white' }}>
                    KEEBOHEALTH - CARDIAC CARE
                  </textPath>
                </text>
              </g>

              {/* Nested SVG for Arrow */}
              <svg x='65' y='65' viewBox='0 0 24 48' width='70' height='95'>
                <path d='M12 3v36M6 8l6-6 6 6' stroke='white' strokeWidth='1' fill='none' strokeLinecap='round' strokeLinejoin='round' transform='rotate(45, 13, 20)' />
              </svg>
            </svg>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );

  const plansPageChildCard = (
    <Grid2
      /* AOS */
      // data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */

      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        // data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        size={{ xs: 9.99 }}
        sx={{
          gap: '20px',
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
        }}>
        <Typography
          sx={{
            color: '#fff',
            fontWeight: 600,
            fontSize: '48px',
          }}>
          Discover the perfect plan for you
        </Typography>
        <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#fff' }}>Choose the plan that best fits your needs.</Typography>
      </Grid2>
    </Grid2>
  );

  const featuresPageChildCard = (
    <Grid2
      /* AOS */
      //  data-aos='fade-up'
      // data-aos-duration='1000'
      /* AOS */

      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        sx={{
          gap: '20px',
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
        }}
        size={{ xs: 9.99 }}>
        <Grid2>
          <Typography sx={{ fontSize: '48px', fontWeight: 600, color: '#fff' }}>How KeeboHealth works ? </Typography>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 7 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#fff' }}>
            KeeboHealth leverages cutting-edge technology to provide continuous monitoring and personalised care for heart failure patients, with few easy steps.
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );

  const contactPageChildCard = (
    <Grid2
      container
      sx={{
        color: 'white',
        textAlign: 'left',
        paddingBottom: 20,
        background: 'rgb(17, 24, 39)',
      }}
      size={{ xs: 12 }}>
      <Grid2
        /* AOS */
        data-aos='fade-up'
        // data-aos-duration='1000'
        /* AOS */

        container
        maxWidth={'1440px'}
        sx={{
          margin: 'auto',
          marginTop: 25,
          flexDirection: 'column',
          gap: '20px',
        }}
        size={{ xs: 9.99 }}>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <Typography sx={{ fontSize: '48px', fontWeight: 600, color: '#fff' }}>Reach out to us for expert care, support & partnership</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#fff' }}>Whether you have questions, feedback, or want to explore partnership opportunities, we're here to assist.</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid2 id={'App'} container direction={'column'} size={12} margin={'auto'}>
        {/* Background Image */}
        {!hasNoSpecifiedTexts(pathname) && (
          <Grid2 ref={bgImageRef} size={12} margin={'auto'}>
            <span className='header-image-background'>
              <img src={header} alt='user-section' className='header-image' />
            </span>
          </Grid2>
        )}

        {hasNoSpecifiedTexts(pathname) && (
          <Grid2 size={12} margin={'auto'}>
            {pathname === '/plans' && plansPageChildCard}
            {pathname === '/features' && featuresPageChildCard}
            {pathname === '/contact' && contactPageChildCard}
          </Grid2>
        )}

        {/* Sticky Navbar */}
        <Grid2 container size={10} margin={'auto'} data-scroll data-scroll-sticky data-scroll-target='.main-content'>
          <Navbar size={10.25} children={!hasNoSpecifiedTexts(pathname) && landingPageChildCard} />
        </Grid2>

        {/* Scrollable content */}
        <Grid2 container size={10} margin={'auto'} maxWidth={'1440px'} data-scroll-container ref={smoothScrollRef} className='main-content'>
          <HomeApp />
        </Grid2>

        <Grid2 container size={10} margin={'auto'} maxWidth={'1440px'}>
          {hasNoSpecifiedTexts(pathname) && <Footer2 />}
        </Grid2>
      </Grid2>

      <Grid2 container size={12} margin={'auto'}>
        {!hasNoSpecifiedTexts(pathname) && <Footer />}
      </Grid2>
    </ThemeProvider>
  );
}

export default App;
